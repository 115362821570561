<template>
  <div :class="{'hidden':hidden,'crm-pagination':settings.sideTheme=== 'theme-crm'}" class="pagination-container">
    <el-pagination
      :small="isSmall"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      :pager-count="pagerCount"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @prev-click="handlePrevClick"
      @next-click="handleNextClick"
    />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'
import { mapState } from 'vuex'

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    pagerCount: {
      // 默认情况下，当总页数超过7页时，Pagination会折叠多余的页码按钮。通过pager-count属性可以设置最大页码按钮数
      type: Number,
      default: 7
    }
  },
  computed: {
    ...mapState(['settings']),
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handlePrevClick(currentPage) {
      this.$emit('prev', { currentPage, limit: this.pageSize })
    },
    handleNextClick(currentPage) {
      this.$emit('next', { currentPage, limit: this.pageSize })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  background: #fff;
  /* padding: 32px 16px; */
}

.pagination-container.hidden {
  display: none;
}


/* 修改crm系统分页栏的颜色 */
.crm-pagination {
  ::v-deep {
    .el-pager {
      li:not(.disabled).active {
        background-color: #ea8a3d;
      }
    }

  }

}
</style>
