<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App',
  data() {
    return {}
  },
  watch: {
    $route(to, from) {
      console.log('app.vue')
    }
  }
}
</script>
<script type="text/javascript">
(function() {
  var ma = document.createElement('script');
  ma.type = 'text/javascript';
  ma.async = true;
  ma.src = '/actionRecord.js';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(ma, s);
})();
</script>

<style>
body {
  font-family:”Microsoft YaHei”,Arial,Helvetica,sans-serif,”宋体”;
}
/*全局滚动条样式修改*/
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
  width: 12px;  /*滚动条宽度*/
  height: 12px;  /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 6px;  /*滚动条的背景区域的圆角*/
  background-color: #fff;/*滚动条的背景颜色*/
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
  border-radius: 6px;  /*滚动条的圆角*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: rgba(0, 0, 0, .1);  /*滚动条的背景颜色*/
}

/*font-awesome字体图标*/
.fa{
  margin-right: 6px;
}
</style>
